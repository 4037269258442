import { Box, Container, useColorModeValue, Button } from "@chakra-ui/react"
import { motion, useTransform, useViewportScroll } from "framer-motion"
import React from "react"
import RouterLink from "../../DefinitionComponents/Utility/Routing/RouterLink"
import MobileMenu from "./MobileMenu"
import pdf1 from "/static/files/1905-anamnesebogen-voltmann.pdf"

import FadeInAnimation from "../../DefinitionComponents/Animation-Components/FadeInAnimation"
export default function Navbar({ links, linkKey, links1, linkKey1 }) {
  //const navBg = useColorModeValue("transparent", "transparent")
  const navBorder = useColorModeValue("white", "brand.blue")
  const linkColor = useColorModeValue("brand.tan.primary", "#1B0F67")
  const navBg = useColorModeValue("#fffffff", "white")
  const MotionBox = motion(Box)

  const containerVariants = {
    initial: {
      width: "0%",
      opacity: 0,
    },
    animate: {
      width: "100%",
      opacity: 1,
      transition: { staggerChildren: 0.1, duration: 0, when: "beforeChildren" },
    },
  }

  const { scrollY } = useViewportScroll()
  const opacityEl = useTransform(scrollY, [0, 64, 256], ["0.0", "0.0", "0.97"])
  const colorTrans = useTransform(scrollY, [0, 100], ["#ffffff", "#3E0CA7"])

  const childVariants = {
    initial: {
      scale: 0.8,
      y: 10,
      x: 10,
      opacity: 0,
    },
    animate: {
      scale: 1,
      y: 0,
      x: 0,
      opacity: 1,
      transition: {
        duration: 0.4,
      },
    },
  }
  return (
    <>
      <MobileMenu
        links={links}
        linkKey={linkKey}
        links1={links1}
        linkKey1={linkKey1}
      />
      <MotionBox
        as="nav"
        boxSizing={"border-box"}
        position={"fixed"}
        top={0}
        left={0}
        zIndex={10}
        w="100%"
        h={{ base: "0px", md: "0px", lg: "88px" }}
      >
        <MotionBox
          w={{ base: "0", md: "100%" }}
          zIndex="-1"
          display={{ base: "none", md: "block" }}
          minH={{ base: "0px", md: "0px", lg: "88px" }}
          position="absolute"
          h={{ base: "0px", md: "0px", lg: "88px" }}
          style={{ opacity: opacityEl }}
          background={"#ffffff"}
        ></MotionBox>
        <Container
          h={{ base: "0px", md: "0px", lg: "88px" }}
          zIndex="222"
          display={{ base: "none", lg: "flex" }}
          pb={4}
          variant="layoutContainer"
          alignItems={"center"}
          px={{ base: "2rem", lg: "px" }}
          justifyContent={"space-between"}
          gap={8}
        >
          <MotionBox pt={6} pl={4}>
            <RouterLink isExternal={false} link={"/"}>
              <svg
                width="77"
                height="77"
                viewBox="0 0 587 588"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M468.09 141.92C303.57 182.52 201.02 399.03 168.61 440.69C161.13 450.3 156.5 451.02 155.08 439.98C149.39 397.24 223.1 198.9 236.63 160.44C239.12 154.03 247.31 153.32 244.46 161.15C230.57 200.68 152.23 402.59 157.22 439.98C158.29 447.81 161.49 447.81 166.84 441.05C193.9 405.08 302.86 180.74 468.45 140.14C470.23 139.43 470.59 141.56 468.09 141.92Z"
                  fill="#DCE0EA"
                />
                <path
                  d="M359.12 318.19C351.64 322.82 297.52 349.17 275.79 349.88C249.79 350.95 287.18 308.22 308.55 308.22C319.59 308.22 316.38 316.41 305.7 322.11C298.93 326.03 288.96 328.88 277.57 329.23C268.67 338.85 264.39 348.46 276.15 348.1C296.45 347.39 351.64 320.68 358.41 316.76L359.12 318.18V318.19ZM280.07 327.09C290.04 326.73 298.94 323.89 305 320.68C309.27 318.19 315.68 312.85 307.49 312.85C299.3 312.85 287.91 319.62 280.07 327.09Z"
                  fill="#DCE0EA"
                />
                <path
                  d="M479.73 318.55C462.99 326.74 411 330.3 394.62 330.66C392.48 344.55 379.31 359.86 364 359.86C333.38 359.86 354.39 314.99 376.82 303.24C382.16 300.75 386.79 303.6 379.67 307.87C359.37 319.98 338.72 358.08 364.36 358.08C378.6 358.08 390 344.19 392.49 330.66C390.35 330.66 386.44 331.02 382.52 330.66C365.07 329.24 370.77 314.99 384.66 314.99C392.49 314.99 395.34 321.76 394.63 328.52C410.65 328.16 462.64 324.96 479.38 316.77L479.74 318.55H479.73ZM384.3 317.13C372.55 317.13 368.63 327.46 382.88 328.53C386.8 328.89 390.71 328.53 392.85 328.53C392.85 322.83 390.71 317.13 384.3 317.13Z"
                  fill="#DCE0EA"
                />
                <path
                  d="M293.17 587C455.083 587 586.34 455.743 586.34 293.83C586.34 131.917 455.083 0.659912 293.17 0.659912C131.257 0.659912 0 131.917 0 293.83C0 455.743 131.257 587 293.17 587Z"
                  fill="#26095F"
                />
                <path
                  d="M468.09 141.58C303.57 182.18 201.02 398.69 168.61 440.35C161.13 449.96 156.5 450.68 155.08 439.64C149.39 396.9 223.1 198.56 236.63 160.1C239.12 153.69 247.31 152.98 244.46 160.81C230.57 200.34 152.23 402.25 157.22 439.64C158.29 447.47 161.49 447.47 166.84 440.71C193.9 404.74 302.86 180.4 468.45 139.8C470.23 139.09 470.59 141.22 468.09 141.58Z"
                  fill="#DCE0EA"
                />
                <path
                  d="M359.12 317.85C351.64 322.48 297.52 348.83 275.79 349.54C249.79 350.61 287.18 307.88 308.55 307.88C319.59 307.88 316.38 316.07 305.7 321.77C298.93 325.69 288.96 328.54 277.57 328.89C268.67 338.51 264.39 348.12 276.15 347.76C296.45 347.05 351.64 320.34 358.41 316.42L359.12 317.84V317.85ZM280.07 326.75C290.04 326.39 298.94 323.55 305 320.34C309.27 317.85 315.68 312.51 307.49 312.51C299.3 312.51 287.91 319.28 280.07 326.75Z"
                  fill="#DCE0EA"
                />
                <path
                  d="M479.73 318.21C462.99 326.4 411 329.96 394.62 330.32C392.48 344.21 379.31 359.52 364 359.52C333.38 359.52 354.39 314.65 376.82 302.9C382.16 300.41 386.79 303.26 379.67 307.53C359.37 319.64 338.72 357.74 364.36 357.74C378.6 357.74 390 343.85 392.49 330.32C390.35 330.32 386.44 330.68 382.52 330.32C365.07 328.9 370.77 314.65 384.66 314.65C392.49 314.65 395.34 321.42 394.63 328.18C410.65 327.82 462.64 324.62 479.38 316.43L479.74 318.21H479.73ZM384.3 316.79C372.55 316.79 368.63 327.12 382.88 328.19C386.8 328.55 390.71 328.19 392.85 328.19C392.85 322.49 390.71 316.79 384.3 316.79Z"
                  fill="#DCE0EA"
                />
                <path
                  d="M223.64 440.66V411.14H228.642L238.4 430.738L248.158 411.14H253.16V440.66H247.953V423.03L239.384 440.66H237.416L228.847 423.03V440.66H223.64ZM267.934 440.66V411.14H287.204V416.347H273.51V422.661H284.744V427.868H273.51V435.453H287.204V440.66H267.934ZM301.138 440.66V411.14H310.691C310.923 411.14 311.401 411.147 312.126 411.16C312.85 411.174 313.547 411.222 314.217 411.304C316.595 411.591 318.61 412.418 320.264 413.784C321.918 415.151 323.175 416.887 324.036 418.991C324.897 421.096 325.328 423.399 325.328 425.9C325.328 428.401 324.897 430.704 324.036 432.808C323.175 434.913 321.918 436.649 320.264 438.015C318.61 439.382 316.595 440.209 314.217 440.496C313.547 440.578 312.85 440.626 312.126 440.639C311.401 440.653 310.923 440.66 310.691 440.66H301.138ZM306.796 435.412H310.691C311.06 435.412 311.558 435.405 312.187 435.391C312.816 435.364 313.383 435.303 313.889 435.207C315.173 434.947 316.219 434.346 317.025 433.403C317.845 432.46 318.446 431.326 318.829 430C319.225 428.674 319.424 427.308 319.424 425.9C319.424 424.424 319.219 423.023 318.809 421.697C318.412 420.372 317.804 419.251 316.984 418.335C316.164 417.42 315.132 416.839 313.889 416.593C313.383 416.484 312.816 416.422 312.187 416.408C311.558 416.395 311.06 416.388 310.691 416.388H306.796V435.412Z"
                  fill="#DCE0EA"
                />
              </svg>
            </RouterLink>
          </MotionBox>
          <MotionBox
            variants={containerVariants}
            initial="initial"
            animate="animate"
            borderColor={navBorder}
            pt={2}
            w={"100%"}
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"flex-end"}
          >
            <MotionBox
              w={"100%"}
              display={"flex"}
              justifyContent={"flex-end"}
              alignItems={"center"}
              gap={8}
              px={12}
              boxSizing={"border-box"}
            >
              {links.map((link, index) => (
                <MotionBox variants={childVariants} key={linkKey + index}>
                  <RouterLink
                    fontSize="md"
                    variant="navlink"
                    isExternal={link.isExternal}
                    link={link.link}
                    color={linkColor}
                  >
                    <motion.span style={{ color: colorTrans, opacity: 0.8 }}>
                      {link.linkText}
                    </motion.span>
                  </RouterLink>
                </MotionBox>
              ))}
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} href="/arbeitemituns">
                  <Button
                    color="white"
                    borderColor={"brand.violet.primary"}
                    bg="brand.violet.primary"
                    variant="ghostsmall"
                  >
                    Jobs
                  </Button>
                </RouterLink>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink isExternal={false} href={pdf1}>
                  <Button
                    color="white"
                    borderColor={"brand.violet.primary"}
                    bg="brand.violet.primary"
                    variant="ghostsmall"
                  >
                    Anamnesebogen PDF
                  </Button>
                </RouterLink>
              </FadeInAnimation>
              <FadeInAnimation
                threshold={0.4}
                delay={0.5}
                duration={1.4}
                initialX={0}
                initialY={10}
              >
                <RouterLink
                  isExternal={true}
                  href="https://www.doctolib.de/praxis/wilhelmshaven/jade-klinik-dr-jann-voltmann-kollegen/booking/motive-categories?placeId=practice-349684&specialityId=1308&telehealth=false"
                >
                  <Button variant="ghostsmall">Termine online</Button>
                </RouterLink>
              </FadeInAnimation>
            </MotionBox>
          </MotionBox>
        </Container>
      </MotionBox>
    </>
  )
}
