import { Box, Container } from "@chakra-ui/react"
import React from "react"
import ImgTextModule from "./ImgTextModule"
import FadeInAnimation from "../DefinitionComponents/Animation-Components/FadeInAnimation"
import RouterLink from "../DefinitionComponents/Utility/Routing/RouterLink"
import { Grid, GridItem, Text, Button } from "@chakra-ui/react"

function ThreeColumnGridBoxesDocs() {
  return (
    <Container variant="layoutContainer" mt={2} mb={24}>
      <Grid
        px={{ base: 4, md: 12, lg: 4, xl: 0 }}
        templateColumns={{
          base: "repeat(4, 1fr)",
          md: "repeat(4 , 1fr)",
          lg: "repeat(4, 1fr)",
          xl: "repeat(12, 1fr)",
        }}
        gap={{ base: 2, md: 2 }}
        my={0}
        minH={{ base: "50vh", lg: "50vh" }}
      >
        <GridItem colSpan="6">
          <ImgTextModule
            h="100%"
            src={"doc-f-1.mp4"}
            delay={0.2}
            duration={0.5}
            threshold={0.3}
            initialY={10}
            initialX={10}
            video={true}
            mediaOpacity={1}
            bgColor="brand.gray.100"
          >
            <Box p={{ base: 0, lg: "8" }}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="brand.white"
                  fontWeight="normal"
                  fontSize={{ base: "4xl", lg: "4xl" }}
                >
                  Kerstin
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text
                  color="brand.tan.ulight"
                  mb={4}
                  maxW={{ base: "100%", lg: "100%" }}
                  fontSize="lg"
                >
                  Ihre Zahnärztin
                </Text>
                {/* <RouterLink isExternal={false} link={"/aesthetics/hyaluron"}>
                  <Button
                    mt={2}
                    variant="solid"
                    color="brand.tan.primary"
                    bg="brand.white"
                  >
                    mehr
                  </Button>
                </RouterLink> */}
              </FadeInAnimation>
            </Box>
          </ImgTextModule>
        </GridItem>

        <GridItem colSpan="6">
          <ImgTextModule
            h="100%"
            src={"doc-m-1.mp4"}
            delay={0.9}
            duration={0.5}
            threshold={0.3}
            initialY={10}
            initialX={10}
            video={true}
            mediaOpacity={1}
            bgColor="brand.gray.100"
          >
            <Box p={{ base: 0, lg: "8" }}>
              <FadeInAnimation
                threshold={0.4}
                delay={0.4}
                duration={0.7}
                initialX={0}
                initialY={-10}
              >
                <Text
                  color="brand.white"
                  fontWeight="normal"
                  fontSize={{ base: "4xl", lg: "4xl" }}
                >
                  Eike
                </Text>
              </FadeInAnimation>

              <FadeInAnimation
                threshold={0.4}
                delay={1}
                duration={0.7}
                initialX={0}
                initialY={10}
              >
                <Text
                  color="brand.tan.ulight"
                  mb={4}
                  maxW={{ base: "100%", lg: "100%" }}
                  fontSize="lg"
                >
                  Ihr Zahnarzt. Spezialist für Implantologie.
                </Text>
                {/* <RouterLink isExternal={false} link={"/aesthetics/hyaluron"}>
                  <Button
                    mt={2}
                    variant="solid"
                    color="brand.tan.primary"
                    bg="brand.white"
                  >
                    mehr
                  </Button>
                </RouterLink> */}
              </FadeInAnimation>
            </Box>
          </ImgTextModule>
        </GridItem>
      </Grid>
    </Container>
  )
}

export default ThreeColumnGridBoxesDocs
